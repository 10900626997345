<template>
<div class="menu-list">
    <h2>卖家中心</h2>
    <a-menu :selectedKeys="selectedKeys" mode="inline" theme="light">
        <a-menu-item v-for="(item) in list" :key="item.route">
            <router-link :to="item.route + ($route.query.entityId && `?entityId=${$route.query.entityId}`)">
                {{item.menuName}}
            </router-link>
        </a-menu-item>
    </a-menu>
</div>
  
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    data() {
        return {
            selectedKeys: [],
        };
    },
    computed: {
        ...mapGetters(['getRoleS']),
        list() {
            const menu = this.getRoleS.find(item => item.entityId == this.$route.query.entityId)
            if (menu) {
                const obj = {
                    "SM001": '/seller-content',
                    "SM002": '/seller-content/store-manage',
                    "SM003": '/seller-content/order-manage',
                    "SM004": '/ceshi',
                }
                return menu.menuListVoList.map(item => (({ ...item, route: obj[item.menuCode]})))
            }
            return []
        }
    },
    mounted () {
        this.updateMenu()
        // console.log('this,',this.list)
    },
    watch: {
    '$route' () {
        this.updateMenu()
        }
    },
    methods: {
        handleClick() {
            this.updateMenu()
        },
        updateMenu () {
            const routes = this.$route.matched.concat()
            this.selectedKeys = [ routes.pop().path ]
        }
    }
}
</script>

<style lang="less" scoped>
h2 {
    padding-left: 24px;
    margin: 14px 0;
    font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    color: #666666;
}
.menu-list {
    width: 250px;
    background: #fff;
    border-right: 1px solid #e8e8e8;
}
/deep/ .ant-menu-item {
    &:nth-child(1) {
        margin-top: 0;
    }
}
/deep/ .ant-menu-item {
    a {
        color: rgba(0, 0, 0, 0.65);
    }
}
/deep/ .ant-menu-item-selected {
    a {
        color: @primary;
    }
}
/deep/ .ant-menu-inline {
    border: none;
}
/deep/ .ant-menu-item::after {
    left: 0;
    right: auto;
}
</style>