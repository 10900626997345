<template>
  <div class="content">
    <!-- 导航 -->
    <a-breadcrumb separator=">" class="breadcrumb">
      <a-breadcrumb-item>
        <a @click.prevent="$router.push('/index')">首页</a>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        {{$route.meta.title}}
      </a-breadcrumb-item>
    </a-breadcrumb>
    <div class="seller-content">
      <Menu />
      <div style="width: calc(100% - 250px);">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from './components/Menu.vue'
export default {
  components: {
    Menu
  }
}
</script>

<style lang="less" scoped>
.content {
  max-width: 1440px;
  min-width: 990px;
  margin: 0 auto;
  @media screen and (max-width: 1440px){
    padding: 0 15px;
  }
}
.seller-content {
  margin: 0 auto;
  margin-bottom: 64px;
  display: flex;
  border: 1px solid #e8e8e8;
  .container {
    width: 100%;
    background: #fff;
    padding: 16px 28px;
  }
}
.breadcrumb{
  min-width: 960px;
  font-size: 14px;
}
</style>